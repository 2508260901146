import React from 'react';
import { Button } from '@depop/web-ui-kit/Button';

import styles from './styles.module.css';

type Props = {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
};

export function ToolButton({ label, icon, onClick }: Props) {
  return (
    <Button as="button" className={styles.toolButton} onClick={onClick} outline>
      {icon}
      <div>{label}</div>
    </Button>
  );
}
