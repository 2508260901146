import {
  PriceEstimate,
  TransformedPriceEstimateDictionary,
} from '@/modules/price/types';
import { ProductPrice } from '@/modules/product/types';

export function getProductPriceFromPricing(
  pricing: TransformedPriceEstimateDictionary
): ProductPrice {
  const finalPricing = pricing[pricing.final_price_key] as PriceEstimate;

  return {
    isReduced: pricing.is_reduced,
    currencyName: pricing.currency_name,
    baseCurrentPrice: finalPricing.price_breakdown.price.amount,
    baseOriginalPrice: pricing.original_price.price_breakdown.price.amount,
    totalCurrentPrice: finalPricing.total_price,
    totalOriginalPrice: pricing.original_price.total_price,
    currentPriceFees: finalPricing.price_breakdown.buyer_fee?.amount,
    currentSalesTax: finalPricing.price_breakdown.tax?.amount,
  };
}
