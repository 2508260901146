'use client';
import React from 'react';

import { LocationContext } from './LocationContext';
import { useCurrentLocation } from './useCurrentLocation';

type Props = {
  defaultLocation: string;
  localisedRoutes: string[];
  supportedLocations: string[];
  children: React.ReactNode;
};

function LocationProvider({
  children,
  defaultLocation,
  localisedRoutes,
  supportedLocations,
}: Props) {
  const { location } = useCurrentLocation();
  return (
    <LocationContext.Provider
      value={{
        userLocation: location,
        defaultLocation,
        localisedRoutes,
        supportedLocations,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
}

export { LocationProvider };
