'use client';

import React, { useEffect, useRef } from 'react';
import { usePathname } from 'next/navigation';

import styles from './styles.module.css';

type Props = {
  children: React.ReactNode;
};
export function FocusWrapper({ children }: Props) {
  const focusRef = useRef<HTMLDivElement | null>(null);
  const pathName = usePathname();

  useEffect(() => {
    if (pathName) {
      /*
        For better keyboard a11y, we reset the focus
        to the top of the page on route change
      */
      if (focusRef.current) {
        focusRef.current.focus();
      }
    }
  }, [pathName]);

  return (
    <div className={styles.focusWrapper} ref={focusRef} tabIndex={-1}>
      {children}
    </div>
  );
}
