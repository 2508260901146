import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["AdminToolbox"] */ "/usr/src/app/src/components/AdminToolbox/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FocusWrapper"] */ "/usr/src/app/src/components/FocusWrapper/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProgressBar"] */ "/usr/src/app/src/components/ProgressBar/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RouteChangeListener"] */ "/usr/src/app/src/components/RouteChangeListener/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InitActivityTracker"] */ "/usr/src/app/src/modules/activityTracker/InitActivityTracker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ObserveFonts"] */ "/usr/src/app/src/modules/fonts/ObserveFonts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageProvider"] */ "/usr/src/app/src/modules/i18n/LanguageProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LocationProvider"] */ "/usr/src/app/src/modules/location/LocationProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InitBing"] */ "/usr/src/app/src/modules/marketing/InitBing.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InitRealUserMonitoring"] */ "/usr/src/app/src/modules/observability/InitRealUserMonitoring.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InitPerformanceMetrics"] */ "/usr/src/app/src/modules/performance/InitPerformanceMetrics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReduxProvider"] */ "/usr/src/app/src/modules/redux/ReduxProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InitSift"] */ "/usr/src/app/src/modules/security/InitSift.tsx");
