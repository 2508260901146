'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { useSessionId } from '@/modules/storage/useSessionId';
import { getPersistentId } from '@/modules/activityTracker/helpers';

/**
 * This component exists because I found the Meta pixel did not behave as expected when the user navigated to a new page.
 * Next doesn't provide route change handling out of the box with the app router
 * so we have this instead. We might well build other stuff into this later but for now, it sends fb tracking events
 * when the route changes and those look just like the events in the legacy app.
 * @returns null
 */

export function RouteChangeListener() {
  const pathname = usePathname();
  const params = useSearchParams();
  const sessionId = useSessionId();

  const [_, user] = useCurrentUser();
  const { activityTrackerClient, reinitialiseATSession } = useActivityTracker({
    userId: user?.id,
  });

  useEffect(() => {
    async function handleATSession() {
      const persistentId = await getPersistentId();
      if (activityTrackerClient.initCalled && persistentId) {
        reinitialiseATSession([], user?.id);
      }
    }
    if (window.fbq) {
      if (pathname.includes('search')) {
        window.fbq('track', 'Search', {
          search_string: params.get('q'),
        });
      } else {
        window.fbq('track', 'PageView');
      }
    }
    if (sessionId) {
      handleATSession();
    }
  }, [pathname, sessionId]);

  return null;
}
