import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { RootState } from '../stores/storeClient';

export interface SearchIDState {
  optimisticUuid: string;
}

const initialState: SearchIDState = {
  optimisticUuid: uuidv4(),
};

/**
 * See: https://depopmarket.atlassian.net/wiki/spaces/WP/pages/4370792609/Search+IDs+on+web+depop-search-id+header
 *
 * Also see note in `useProductSearch`
 */
export const searchIdSlice = createSlice({
  name: 'sellerHub',
  initialState,
  reducers: {
    generateNewOptimisticSearchUuid: (state) => {
      state.optimisticUuid = uuidv4();
    },
  },
});

export const { generateNewOptimisticSearchUuid } = searchIdSlice.actions;

// eslint-disable-next-line import/no-default-export
export default searchIdSlice.reducer;

/**
 * @returns {string} A UUID to be used whenever a new, unique search request is made.
 */
export function useOptimisticSearchUuid() {
  return useSelector((state: RootState) => state.searchId.optimisticUuid);
}
