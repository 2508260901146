import {
  MagicLinkRequest,
  MagicLinkSignupEmailVerifyRequest,
  MagicLinkSignupEmailVerifyResponse,
} from './types';

import { isEmail } from '@/utils/isEmail.ts';
import http from '@/modules/http';
import {
  MAGIC_LINK_REGISTER_ENDPOINT,
  MAGIC_LINK_REQUEST_ENDPOINT,
  MAGIC_LINK_REQUEST_SYNC_ENDPOINT,
  SIGNUP_TOKEN_VALIDATION_ENDPOINT,
} from '@/constants/endpoints';
import { Platform } from '@/modules/activityTracker/constants';
import { AxiosCompatibleResponse } from '@/modules/http/types.ts';

export function requestMagicLink(params: MagicLinkRequest) {
  return http.post(MAGIC_LINK_REQUEST_ENDPOINT, params);
}

export function registerMagicLink(params: MagicLinkRequest) {
  return http.post(MAGIC_LINK_REGISTER_ENDPOINT, params);
}

export function requestMagicLinkSync(identifier: string) {
  const payload = isEmail(identifier)
    ? { email: identifier }
    : { username: identifier };

  return http.post<{ token: string }>(MAGIC_LINK_REQUEST_SYNC_ENDPOINT, {
    ...payload,
    requesting_platform: Platform.Web,
  });
}

export function verifyEmailMagicLinkSignup(
  params: MagicLinkSignupEmailVerifyRequest
): Promise<AxiosCompatibleResponse<MagicLinkSignupEmailVerifyResponse>> {
  return http.put(SIGNUP_TOKEN_VALIDATION_ENDPOINT, params);
}
