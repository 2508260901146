'use client';

import { useEffect, useState } from 'react';
import {
  datadogRum,
  DefaultPrivacyLevel,
  RumInitConfiguration,
} from '@datadog/browser-rum';
import { useQueryClient } from '@tanstack/react-query';
import { useParams, useSelectedLayoutSegments } from 'next/navigation';
import { datadogLogs } from '@datadog/browser-logs';

import { beforeSendRumEvent as beforeSend } from './beforeSendRumEvent';
import { getDatadogRumSampleRate } from './getRumSampleRate';

import { useCurrentLanguage } from '@/modules/language/useCurrentLanguage';
import { useCurrentLocation } from '@/modules/location/useCurrentLocation';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { CachedExperiments } from '@/modules/experiments/types';
import { RQ_PREVIEW_EXPS_CACHE } from '@/modules/ReactQuery/cacheKeys';
import { constructDynamicRoute } from '@/modules/routing/constructDynamicRoute';
import { CustomEvents } from '@/constants/customEvents';
import { useTrackingProviders } from '@/modules/trackingProviders/useTrackingProviders';
import { DD_SERVICE } from '@/modules/observability/constants';

export function InitRealUserMonitoring() {
  const params = useParams();
  const segments = useSelectedLayoutSegments();
  const viewName = constructDynamicRoute(params, segments);
  const shouldInit = process.env.NEXT_PUBLIC_DEPLOY_ENV !== 'development';

  const client = useQueryClient();
  const { language } = useCurrentLanguage();
  const { location } = useCurrentLocation();
  const [_, user] = useCurrentUser();
  const [didInit, setDidInit] = useState(false);
  // gets set to true when the user accepts analytics cookies
  const [readyToInitRum, setReadyToInitRum] = useState(false);

  const trackingProviders = useTrackingProviders();

  const experiments = client.getQueryData<CachedExperiments>([
    RQ_PREVIEW_EXPS_CACHE,
  ]);

  function checkCookieStatus() {
    const { analytics } = trackingProviders.getCurrentConsentChoices();
    return analytics;
  }

  function handleCookieAcceptance() {
    setReadyToInitRum(checkCookieStatus());
  }

  useEffect(() => {
    setReadyToInitRum(checkCookieStatus());
    window.addEventListener(
      CustomEvents.CookieAcceptance,
      handleCookieAcceptance
    );

    return () => {
      window.removeEventListener(
        CustomEvents.CookieAcceptance,
        handleCookieAcceptance
      );
    };
  }, []);

  useEffect(() => {
    // If DD_RUM init configs already exists, DD has already been loaded
    if (!readyToInitRum || didInit || !shouldInit) {
      return;
    }
    const config: RumInitConfiguration = {
      applicationId: process.env.NEXT_PUBLIC_DD_RUM_APP_ID!,
      clientToken: process.env.NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN!,
      site: 'datadoghq.com',
      service: DD_SERVICE,
      env: process.env.NEXT_PUBLIC_DEPLOY_ENV,
      trackUserInteractions: true,
      defaultPrivacyLevel: DefaultPrivacyLevel.MASK,
      allowedTracingUrls: [
        'https://webapi.depop.com',
        'https://webapi-staging.depop.com',
      ],
      version: process.env.NEXT_PUBLIC_APP_VERSION!,
      trackViewsManually: true,
      beforeSend,
      ...getDatadogRumSampleRate(process.env.NEXT_PUBLIC_DEPLOY_ENV!),
    };

    datadogRum.init(config);
    datadogRum.startSessionReplayRecording();

    if (user?.id) {
      /*
        Not technically RUM but this is a conveniant place to init logs context as
        it can also tap into the consent mechanism in the RUM logic.
      */
      datadogLogs.setUser({ id: user?.id.toString() });
    }

    const hasConfigs = !!window?.DD_RUM?.getInitConfiguration();
    if (hasConfigs) {
      setDidInit(true);
    }
  }, [readyToInitRum, user?.id]);

  useEffect(() => {
    if (didInit) {
      const appContext = {
        language,
        locale: location,
        experiments,
        user: {
          isAuthenticated: Boolean(user?.id),
          dateJoined: user?.dateJoined,
          verified: user?.verified,
          // @todo C.P. 13/11/2023 add sellerStatus data when available
        },
      };
      datadogRum.setGlobalContext(appContext);
    }
  }, [didInit, user?.id, language]);

  useEffect(() => {
    if (didInit) {
      /**
       * We have set trackViewsManually as true, so that we can
       * group our metrics by their dynamic route, where possible.
       * e.g /products/[slug] for our product page
       */
      datadogRum.startView({
        name: viewName,
        service: DD_SERVICE,
        version: process.env.NEXT_PUBLIC_APP_VERSION,
      });
    }
  }, [didInit, viewName]);

  return null;
}
