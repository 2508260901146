export const DEPOP_FONT = 'gtamerica';

export async function observeFontsLoaded() {
  const fontLoadingCompletedEvent = new Event('fontLoadingCompleted');

  document.fonts.ready.then(() => {
    document.documentElement.classList.add(DEPOP_FONT);
    window.dispatchEvent(fontLoadingCompletedEvent);
  });
}
