import { useSelector } from 'react-redux';

import { RootState } from '@/modules/redux/stores/storeClient';
import { languages } from '@/modules/language/constants';

export function useCurrentLanguage() {
  const language =
    useSelector((state: RootState) => state.language)?.value || 'en';

  const availableLanguages = languages.filter((lang) => lang.display);

  return { availableLanguages, language };
}
