import React, { useState } from 'react';
import { Input } from '@depop/web-ui-kit/Input';
import { Button } from '@depop/web-ui-kit/Button';
import { setCookie } from 'cookies-next';

import { requestMagicLinkSync } from '@/modules/magicLinks/api';
import { login } from '@/modules/login/api';
import { ACCESS_TOKEN_KEY } from '@/constants/cookies';

export function SwitchUser() {
  const [inputValue, setInputValue] = useState('');

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(e.target.value);
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const {
      data: { token: magicLinkToken },
    } = await requestMagicLinkSync(inputValue);
    const {
      data: { token: accessToken },
    } = await login({ magicLinkToken });

    setCookie(ACCESS_TOKEN_KEY, accessToken);
    window.location.reload();
  }

  return (
    <form onSubmit={handleSubmit}>
      <Input
        labelText="Username or email"
        id="adminTool__magic-link-login"
        onChange={onInputChange}
        onClear={() => setInputValue('')}
        value={inputValue}
        required
      />
      <Button type="submit" block>
        Log in
      </Button>
    </form>
  );
}
