import { useQuery } from '@tanstack/react-query';
import { useParams } from 'next/navigation';

import { PRODUCT_BASIC_BY_SLUG } from '@/modules/ReactQuery/cacheKeys.ts';
import { getProductBySlug } from '@/modules/product/api.ts';

export function useProductBySlugBasic() {
  const { slug } = useParams<{ slug: string }>();

  return useQuery({
    queryKey: [PRODUCT_BASIC_BY_SLUG, slug],
    queryFn: async () => {
      const res = await getProductBySlug({
        slug,
      });
      return res.data;
    },
  });
}
