import { RumInitConfiguration } from '@datadog/browser-rum';

import { botExemptionUAStrings } from '@/constants/botExemptionUAStrings';

type DatadogRumSampleRateConfig = Pick<
  RumInitConfiguration,
  'sessionSampleRate' | 'sessionReplaySampleRate'
>;

export function getDatadogRumSampleRate(
  environment: string
): DatadogRumSampleRateConfig {
  const regex = new RegExp(botExemptionUAStrings, 'i');

  switch (environment) {
    case 'production':
      /**
       * @TODO TN 16/1/24 - Incrementally ramp down sample rate
       * as traffic to new application increases
       */
      return {
        sessionSampleRate: regex.test(navigator.userAgent) ? 0 : 75,
        sessionReplaySampleRate: 10,
      };
    case 'staging':
      return {
        sessionSampleRate: regex.test(navigator.userAgent) ? 0 : 100,
        sessionReplaySampleRate: 0,
      };
    default:
      return {
        sessionSampleRate: 0,
        sessionReplaySampleRate: 0,
      };
  }
}
