'use client';

import { useEffect, useContext } from 'react';
import {
  useSearchParams,
  useParams,
  usePathname,
  useSelectedLayoutSegments,
} from 'next/navigation';

import { useActivityTracker } from './useActivityTracker';
import { useReferralTracker } from './useReferralTracker';
import { referralEventTypes, ActivityTrackerEventType } from './constants';
import { RefererContext } from './RefererProvider';
import { getRouteName, getRouteView, getReferer } from './referers';
import { getProductSlug } from './helpers';

import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { isWebCrawler } from '@/modules/headers/isWebCrawler';
import { useCurrentLanguage } from '@/modules/language/useCurrentLanguage';

type Props = {
  headers?: Record<string, string>;
};

export function InitActivityTracker({ headers }: Props) {
  const [_, user] = useCurrentUser();
  const {
    initialiseActivityTrackerSession,
    activityTrackerClient,
    sendActivityTrackerEvent,
  } = useActivityTracker({
    userId: user?.id,
  });
  const trackReferralEvent = useReferralTracker();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const params = useParams();
  const segments = useSelectedLayoutSegments();
  const referer = useContext(RefererContext) || '';
  const userAgent = headers?.['user-agent'];
  const secFetchSiteHeader = headers?.['sec-fetch-site'];
  const { language } = useCurrentLanguage();

  /*
  We have this queue to store events that got sent to the AT client before the session and persistent ids
  were ready. In the legacy code we flushed this queue in a function bound to the routechangestart event.
  But that doesn't exist in the new world of next 14, so we do it here.
  */
  if (typeof window !== 'undefined' && window.DEPOP_AT_QUEUE?.length) {
    while (window.DEPOP_AT_QUEUE.length) {
      const ATEvent = window.DEPOP_AT_QUEUE.shift();
      if (ATEvent) {
        sendActivityTrackerEvent(
          ATEvent.event,
          ATEvent.params,
          ATEvent.baseEventOverrides
        );
      }
    }
  }

  useEffect(() => {
    async function init() {
      if (isWebCrawler(userAgent)) {
        return;
      }
      if (!window.DEPOP_AT_QUEUE) {
        window.DEPOP_AT_QUEUE = [];
      }
      // This check is needed because in strict mode this can get called twice during local dev
      if (!activityTrackerClient.initCalled) {
        const { isNewSession, cleanup } =
          await initialiseActivityTrackerSession([], user?.id);
        const queryParams = Object.fromEntries(searchParams.entries());
        // Referral Logic
        const {
          utm_source: source,
          utm_campaign: campaign,
          utm_medium: medium,
          utm_term: term,
          utm_content: content,
          gclid,
        } = queryParams as Record<string, string | undefined>;

        const routeName: string = getRouteName(pathname);

        const campaignUtmTags = (() => {
          if (source || campaign || medium || content || term) {
            return {
              source: Array.isArray(source) ? source[0] : source,
              campaign: Array.isArray(campaign) ? campaign[0] : campaign,
              medium: Array.isArray(medium) ? medium[0] : medium,
              content: Array.isArray(content) ? content[0] : content,
              term: Array.isArray(term) ? term[0] : term,
            };
          }
          if (gclid && !source && !campaign && !medium && !term) {
            return {
              source: 'Paid',
              medium: 'Google',
              content: routeName,
            };
          }
          return { source: getReferer(referer), content: routeName };
        })();

        // JR: this check has been implemented to send this event when users come from external websites.
        // same-site and same-origin values represent traffic from an internal Depop web page which is
        // not the desired traffic we want for this event.

        if (
          secFetchSiteHeader !== 'same-site' &&
          secFetchSiteHeader !== 'same-origin'
        ) {
          trackReferralEvent(referralEventTypes.PAGEVIEW, {
            userId: user?.id,
            url: window.location.href,
            urlParameters: queryParams,
            referer,
            productSlug: getProductSlug(params, segments),
            ...(campaignUtmTags ? { campaignUtmTags } : {}),
          });
        }

        // Only send LinkReceiverAction on valid routes
        if (routeName) {
          sendActivityTrackerEvent(
            ActivityTrackerEventType.LINK_RECEIVER_ACTION,
            {
              landingUrl: window.location.href,
              linkType: 'universal',
              linkreceiverReferrerUrl: referer,
              schemaVersion: '2.0',
            }
          );
        }

        if (isNewSession) {
          // Landing Page View
          sendActivityTrackerEvent(ActivityTrackerEventType.LANDING_PAGE_VIEW, {
            schemaVersion: '3.0',
            landing_url: pathname,
            type: routeName,
            language,
          });

          // Web Campaign View
          if (queryParams.utm_source) {
            sendActivityTrackerEvent(
              ActivityTrackerEventType.WEB_CAMPAIGN_VIEW,
              {
                page: getRouteView(pathname),
                utm: `?${searchParams}`,
              }
            );
          }
        }

        /* eslint-disable-next-line func-style */
        const unloadHandler = () => {
          if (cleanup) {
            cleanup();
          }
        };
        window.addEventListener('beforeunload', unloadHandler);
        return () => {
          window.removeEventListener('beforeunload', unloadHandler);
        };
      }
    }
    init();
  }, []);

  return null;
}
