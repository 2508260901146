import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setCookie } from 'cookies-next';

import { Language } from '@/modules/language/constants';
import { LANGUAGE_KEY } from '@/constants/cookies';

export interface LanguageState {
  value: Language;
}

const initialState: LanguageState = {
  value: Language.en,
};

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<Language>) => {
      state.value = action.payload;
      setCookie(LANGUAGE_KEY, state.value);
    },
  },
});

export const { setLanguage } = languageSlice.actions;

export default languageSlice.reducer;
