import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';

import { rootReducer } from '../rootReducer';

const defaultOptions = {
  reducer: rootReducer,
};

export function getClientStore(
  preloadedState?: Partial<RootState>,
  config?: Partial<ConfigureStoreOptions>
) {
  return configureStore({
    ...defaultOptions,
    ...config,
    preloadedState,
    devTools: process.env.NODE_ENV !== 'production',
  });
}

// Need this store for types only
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const store = configureStore(defaultOptions);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type GetState = typeof store.getState;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
