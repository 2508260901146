import { z } from 'zod';

/**
 * Validates that the input is a string and conforms to email formatting.
 */
export function isEmail(value: string) {
  const emailSchema = z.string().email();
  const result = emailSchema.safeParse(value);

  return result.success;
}
