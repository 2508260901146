import http from '@/modules/http';
import { AxiosCompatibleResponse } from '@/modules/http/types';
import {
  FETCH_PAYMENT_PROVIDERS_ENDPOINT,
  LEGACY_PRODUCT_BY_ID,
  PRODUCT_BY_ID_ENDPOINT,
  PRODUCT_BY_SLUG,
  PRODUCT_BY_SLUG_EXTENDED,
  PRODUCT_MORE_ITEMS,
  UPDATE_SAVED_PRODUCT_ENDPOINT,
} from '@/constants/endpoints.ts';
import {
  FetchMoreItemsResponse,
  LegacyProductById,
  FetchProductMoreItemsRequestParams,
  ProductBySlugExtendedResponse,
  ProductBySlugResponse,
  ProductListingBySlugResponse,
  ProductById,
} from '@/modules/product/types.ts';
import { PaymentProvidersResponse } from '@/modules/checkout/types.ts';

export function getProductById({
  productId,
  sessionId,
  force_fee_calculation = false,
  include_offers = false,
}: {
  productId: string;
  sessionId?: string | null;
  force_fee_calculation?: boolean;
  include_offers?: boolean;
}): Promise<AxiosCompatibleResponse<ProductById>> {
  return http.get(PRODUCT_BY_ID_ENDPOINT.replace(':productId', productId), {
    headers: {
      ...(sessionId && { 'Depop-Session-Id': sessionId }),
    },
    params: {
      force_fee_calculation,
      include_offers,
    },
    withAuth: true,
  });
}

export function getLegacyProductById({
  productId,
  sessionId,
}: {
  productId: string;
  sessionId?: string | null;
}): Promise<AxiosCompatibleResponse<LegacyProductById>> {
  return http.get(LEGACY_PRODUCT_BY_ID.replace(':productId', productId), {
    headers: {
      ...(sessionId && { 'Depop-Session-Id': sessionId }),
    },
  });
}

export function getProductMoreItems(
  productId: string,
  params: FetchProductMoreItemsRequestParams
): Promise<AxiosCompatibleResponse<FetchMoreItemsResponse>> {
  return http.get(PRODUCT_MORE_ITEMS.replace(':productId', productId), {
    params,
    withAuth: true,
  });
}

export function getProductBySlug({
  slug,
}: {
  slug: string;
}): Promise<AxiosCompatibleResponse<ProductBySlugResponse>> {
  return http.get(PRODUCT_BY_SLUG.replace(':slug', slug));
}

export function getProductBySlugExtended({
  lang,
  slug,
  forceFeeCalculation,
}: {
  slug: string;
  forceFeeCalculation: boolean;
  lang?: string;
}): Promise<AxiosCompatibleResponse<ProductBySlugExtendedResponse>> {
  return http.get(PRODUCT_BY_SLUG_EXTENDED.replace(':slug', slug), {
    withAuth: true,
    params: {
      lang,
      force_fee_calculation: forceFeeCalculation,
      include_sold_sizes: true,
    },
  });
}

export function getProductListingBySlug({
  slug,
}: {
  slug: string;
}): Promise<AxiosCompatibleResponse<ProductListingBySlugResponse>> {
  return http.get(PRODUCT_BY_SLUG.replace(':slug', slug), {
    params: {
      usePictureFormats: true,
    },
    headers: {
      ['ot-baggage-x-env-name']: 'p-40d',
    },
    withAuth: true,
  });
}

export function deleteProductSave(productId: number) {
  return http.delete(
    UPDATE_SAVED_PRODUCT_ENDPOINT.replace(':productId', productId.toString()),
    {
      withAuth: true,
    }
  );
}

export function addProductSave(productId: number) {
  return http.put(
    UPDATE_SAVED_PRODUCT_ENDPOINT.replace(':productId', productId.toString()),
    {},
    { withAuth: true }
  );
}

export function getProductPaymentProviders({
  sellerId,
  currency,
  withAuth = false,
}: {
  sellerId: number;
  currency: string;
  withAuth?: boolean;
}): Promise<AxiosCompatibleResponse<PaymentProvidersResponse>> {
  return http.get(
    FETCH_PAYMENT_PROVIDERS_ENDPOINT.replace(':sellerId', sellerId.toString()),
    {
      withAuth,
      params: { currency },
    }
  );
}
