import { Response } from 'node-fetch';
import {
  RumActionEventDomainContext,
  RumErrorEventDomainContext,
  RumEvent,
  RumFetchResourceEventDomainContext,
  RumLongTaskEventDomainContext,
  RumOtherResourceEventDomainContext,
  RumViewEventDomainContext,
  RumXhrResourceEventDomainContext,
} from '@datadog/browser-rum';

import { phraseContainsKeyword } from './filterLog';
import { IGNORED_KEYWORDS } from './constants';

const ALLOWED_RESOURCES = ['depop', 'ctfassets', 'cloudfront'];

function shouldDiscardRumEvent(event: RumEvent, requestError?: Response) {
  if (event.type === 'error') {
    const errorString =
      (event?.error?.message || '') + (event?.error?.stack || '');
    if (phraseContainsKeyword(IGNORED_KEYWORDS, errorString)) {
      return true;
    }
  }

  // Filter out errors from e2e test env
  if (!!window?.navigator?.webdriver) {
    return true;
  }

  if (
    event.type === 'resource' &&
    !phraseContainsKeyword(ALLOWED_RESOURCES, event.resource?.url)
  ) {
    return true;
  }

  // Filter out client-side errors where status code is available
  if (
    requestError?.status &&
    requestError.status >= 400 &&
    requestError.status < 500
  ) {
    return true;
  }

  return false;
}

type RumContext =
  | RumViewEventDomainContext
  | RumActionEventDomainContext
  | RumFetchResourceEventDomainContext
  | RumXhrResourceEventDomainContext
  | RumOtherResourceEventDomainContext
  | RumErrorEventDomainContext
  | RumLongTaskEventDomainContext;

export function beforeSendRumEvent(event: RumEvent, context: RumContext) {
  // @ts-ignore response metadata is sometimes included in context, not reflected within DD types
  const requestError = context?.error?.response;

  if (shouldDiscardRumEvent(event, requestError)) {
    return false;
  }

  event.context = {
    ...event.context,
    ...(!!event.error && {
      requestError,
    }),
  };

  return true;
}
