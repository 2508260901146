import locationReducer from './slices/locationSlice';
import languageReducer from './slices/languageSlice';
import cookiesReducer from './slices/cookieSlice';
import deviceReducer from './slices/deviceSlice';
import sellerOnboardingReducer from './slices/sellerOnboardingSlice';
import shopReducer from './slices/shopSlice';
import sellerhubSlice from './slices/sellerHubSlice';
import searchIdSlice from './slices/searchIdSlice';

export const rootReducer = {
  location: locationReducer,
  language: languageReducer,
  cookies: cookiesReducer,
  device: deviceReducer,
  sellerOnboarding: sellerOnboardingReducer,
  shop: shopReducer,
  sellerHub: sellerhubSlice,
  searchId: searchIdSlice,
};
