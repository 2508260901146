'use client';

import React, { useEffect, useState } from 'react';
import Script from 'next/script';
import { usePathname } from 'next/navigation';

import { useSessionId } from '@/modules/storage/useSessionId';
import { useCurrentUser } from '@/modules/user/useCurrentUser';

export function InitSift() {
  const pathname = usePathname();
  const sessionId = useSessionId();
  const [_, user] = useCurrentUser();
  const userId = user?.id.toString() || '';
  const [hasConfig, setHasConfig] = useState(false);

  function setSiftConfig() {
    const _sift = (window._sift = window._sift || []);

    if (!_sift.length) {
      _sift.push(['_setAccount', process.env.NEXT_PUBLIC_SIFT_BEACON_KEY!]);
      _sift.push(['_setUserId', userId]);
      _sift.push(['_setSessionId', sessionId || '']);
      _sift.push(['_trackPageview']);
    }

    setHasConfig(true);
  }

  useEffect(() => {
    if (!hasConfig && sessionId) {
      setSiftConfig();
    }
  }, [sessionId]);

  useEffect(() => {
    /**
     * We do not want `hasConfig` in the dependency array
     * as we want to fire trackPageView on subsequent route changes
     * AFTER the initial setSiftConfig call on mount
     *  */
    if (hasConfig) {
      window._sift.push(['_trackPageview']);
    }
  }, [pathname]);

  if (!hasConfig) {
    return null;
  }

  return <Script id="sift-script" src="https://cdn.sift.com/s.js" />;
}
