import { useCallback } from 'react';

import { ReferralEventType, UtmTags } from './constants';
import { ActivityTrackerClient } from './ActivityTrackerClient';

type Params = {
  userId?: number;
};

type ReferralEventPayload = {
  userId?: number;
  url?: string;
  urlParameters?: Record<string, string>;
  referer?: string;
  productSlug?: string;
  campaignUtmTags?: UtmTags;
};
/**
 * Similar to the _useActivityTracker_ hook, it uses the _activityTrackerClient_
 * to send events to the activity tracker web-api.
 * However, these referral events are meant to be treated differently than
 * the rest of the activity tracker events by the undelying services and,
 * in order to better separate these concerns, this functionality is provided
 * as a separate hook for consuming client code.
 */
export function useReferralTracker({ userId }: Params = {}) {
  const activityTrackerClient = ActivityTrackerClient.getInstance({ userId });

  const trackReferralEvent = useCallback(
    (
      event: ReferralEventType,
      {
        userId,
        url,
        urlParameters,
        referer,
        productSlug,
        campaignUtmTags,
      }: ReferralEventPayload
    ) =>
      activityTrackerClient.sendReferralEvent({
        event,
        userId,
        url,
        urlParameters,
        referer,
        productSlug,
        campaignUtmTags,
      }),
    []
  );

  return trackReferralEvent;
}
