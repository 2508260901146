'use client';

import https from 'https';

import { useEffect } from 'react';
import { Metric, onCLS, onFCP, onINP, onLCP, onTTFB } from 'web-vitals';

import { Platform } from '@/modules/activityTracker/constants';
import {
  getCurrentSession,
  getPersistentId,
} from '@/modules/activityTracker/helpers';
import http from '@/modules/http';
import { useCurrentRoute } from '@/modules/activityTracker/useCurrentRoute';
import { ROUTE_CHANGE_START_EVENT } from '@/modules/routing/constants';
import { PERFORMANCE_METRICS } from '@/constants/endpoints';
import { recordMetric } from '@/modules/observability/metrics';
import { Stdv1Stat } from '@/modules/observability/constants';

type MetricsSent = Partial<Record<Metric['name'], boolean>>;
type MetricsData = Partial<Record<Metric['name'], number>>;

const baseRequestBody = {
  platform: Platform.Web,
  app_version: `2.0-${process.env.NEXT_PUBLIC_APP_VERSION || 'unknown'}`,
};

/*
This function does two things.
It tracks which metrics have already been sent and prevents them from being sent multiple times.
It returns the function to actually send the data.
*/

function sendPerformanceMetricsFn() {
  const metricsSent: MetricsSent = {};
  const { sessionStorageSessionId } = getCurrentSession();

  return async function (
    metricAspect: Metric['name'],
    metricName: string,
    metricValue: number
  ) {
    const headers = {
      'Depop-Device-Id': (await getPersistentId()) || '',
      'Depop-Session-Id': sessionStorageSessionId || '',
    };
    if (!metricsSent[metricAspect]) {
      metricsSent[metricAspect] = true;

      return http
        .post(
          PERFORMANCE_METRICS,
          {
            ...baseRequestBody,
            event_timestamp: Date.now(),
            metric_aspect: metricAspect,
            metric_name: metricName,
            metric_value:
              metricAspect === 'CLS'
                ? Number(metricValue.toFixed(2))
                : Math.round(metricValue),
          },
          {
            headers,
            httpsAgent: new https.Agent({ keepAlive: true }),
            withAuth: true,
          }
        )
        .catch(() => {
          recordMetric({
            type: 'increment',
            stat: Stdv1Stat['performance.tracking.failed'],
          });
        });
    }

    return Promise.resolve();
  };
}

function getMetricsData() {
  const metricsData: MetricsData = {};

  function setMetricsData(name: Metric['name'], value: number) {
    metricsData[name] = value;
  }

  return {
    metricsData,
    setMetricsData,
  };
}

function handleCollectMetrics(
  metric: Metric,
  metricsData: MetricsData,
  setMetricsData: (name: Metric['name'], value: number) => void
) {
  const { name, value } = metric;
  const existingMetricValue = metricsData[name];

  if (!existingMetricValue || existingMetricValue < value) {
    return setMetricsData(name, value);
  }
}

const sendPerformanceMetrics = sendPerformanceMetricsFn();

export function InitPerformanceMetrics() {
  const currentRoute = useCurrentRoute();

  function handleMetricReporting({ name, value }: Metric) {
    return sendPerformanceMetrics(name, currentRoute, value);
  }

  const { metricsData, setMetricsData } = getMetricsData();

  function handleSendMetricsCollection() {
    Object.entries(metricsData).forEach(([name, value]) =>
      sendPerformanceMetrics(name as Metric['name'], currentRoute, value)
    );
  }

  function handleVisibilityChange() {
    if (document.visibilityState === 'hidden') {
      handleSendMetricsCollection();
    }
  }

  useEffect(() => {
    // We can send these metrics immediately because they only have a single reading
    onTTFB(handleMetricReporting);
    onFCP(handleMetricReporting);
    onLCP(handleMetricReporting);

    // These metrics we need to collect and send the highest reading
    onCLS(
      (metric) => handleCollectMetrics(metric, metricsData, setMetricsData),
      { reportAllChanges: true }
    );
    onINP(
      (metric) => handleCollectMetrics(metric, metricsData, setMetricsData),
      { reportAllChanges: true }
    );

    // We send the CLS & INP metrics with the highest reading for each when the page is hidden, the user navigates away,
    // reloads, closes the page or navigates to a new internal page
    addEventListener('visibilitychange', handleVisibilityChange);
    addEventListener('beforeunload', handleSendMetricsCollection);
    addEventListener('pagehide', handleSendMetricsCollection);
    document.addEventListener(
      ROUTE_CHANGE_START_EVENT,
      handleSendMetricsCollection
    );

    return () => {
      removeEventListener('visibilitychange', handleVisibilityChange);
      removeEventListener('beforeunload', handleSendMetricsCollection);
      removeEventListener('pagehide', handleSendMetricsCollection);
      document.removeEventListener(
        ROUTE_CHANGE_START_EVENT,
        handleSendMetricsCollection
      );
    };
  }, []);

  return null;
}
