'use client';

import React, { useEffect, useState } from 'react';
import { DepopGroupIconIcon } from '@depop/web-ui-kit/Icons/DepopGroupIconIcon';
import { CancelIcon } from '@depop/web-ui-kit/Icons/CancelIcon';
import { ArrowBackIcon } from '@depop/web-ui-kit/Icons/ArrowBackIcon';
import { FlashIcon } from '@depop/web-ui-kit/Icons/FlashIcon';
import { GlobeIcon } from '@depop/web-ui-kit/Icons/GlobeIcon';
import { ClickIcon } from '@depop/web-ui-kit/Icons/ClickIcon';
import { ArrowRotateIcon } from '@depop/web-ui-kit/Icons/ArrowRotateIcon';
import { VideoBasicIcon } from '@depop/web-ui-kit/Icons/VideoBasicIcon';
import { Button } from '@depop/web-ui-kit/Button';
import { setCookie } from 'cookies-next';
import { useSelectedLayoutSegments } from 'next/navigation';
import { ClothesIcon } from '@depop/web-ui-kit/Icons/ClothesIcon';
import { ShopIcon } from '@depop/web-ui-kit/Icons/ShopIcon';
import { IdentificationIcon } from '@depop/web-ui-kit/Icons/IdentificationIcon';

import styles from './styles.module.css';
import { ToolButton } from './components/ToolButton';
import { UserInfo } from './components/Tools/UserInfo';
import { RouteInfo } from './components/Tools/RouteInfo';
import { SwitchUser } from './components/Tools/SwitchUser';
import { ExperimentSwitcher } from './components/Tools/ExperimentSwitcher';
import { ATEvents } from './components/Tools/ATEvents';
import { PreviewEnv } from './components/Tools/PreviewEnv';
import { ProductInfo } from './components/Tools/ProductInfo';
import { ShopInfo } from './components/Tools/ShopInfo';

import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { useDepopChromeExt } from '@/modules/adminTool/useDepopChromeExt';
import {
  getSessionStorageItem,
  removeSessionStorageItem,
  setSessionStorageItem,
} from '@/modules/storage';
import { ACTIVE_TOOL_SESSION_STORAGE_KEY } from '@/modules/adminTool/contants';
import { Tools } from '@/modules/adminTool/types';
import { isServerCheck } from '@/modules/env/isServerCheck';
import { FORCE_TRAFFIC_WEIGHT } from '@/constants/cookies.ts';

export function AdminToolbox() {
  const lastActiveToolKey = !isServerCheck()
    ? getSessionStorageItem(ACTIVE_TOOL_SESSION_STORAGE_KEY)
    : '';
  const [open, setOpen] = useState(Boolean(lastActiveToolKey));
  const [activeToolKey, setActiveToolKey] = useState<string>(
    lastActiveToolKey || ''
  );
  const [activeListKey, setActiveListKey] = useState<'default' | 'more'>(
    'default'
  );
  const [, currentUser] = useCurrentUser();
  const [mounted, setMounted] = useState(false);
  const hasDepopChromeExt = useDepopChromeExt();
  const layoutSegments = useSelectedLayoutSegments();
  const isProductPage = layoutSegments[0] === 'products';
  const isShopPage = layoutSegments?.[1] === '(shop)';

  function isProdEnv() {
    return process.env.NEXT_PUBLIC_DEPLOY_ENV === 'production';
  }

  function toggleAdminToolOpen() {
    setOpen((value) => !value);
  }

  function openTool(toolKey: string) {
    setActiveToolKey(toolKey);
    setSessionStorageItem(ACTIVE_TOOL_SESSION_STORAGE_KEY, toolKey);
  }

  function closeActiveTool() {
    setActiveToolKey('');
    removeSessionStorageItem(ACTIVE_TOOL_SESSION_STORAGE_KEY);
  }

  const MORE_TOOLS: Tools = {
    'Preview Env': {
      icon: <VideoBasicIcon />,
      component: <PreviewEnv />,
    },
    'Switch User': {
      icon: <ArrowRotateIcon />,
      component: <SwitchUser />,
    },
  };

  const TOOLS: Tools = {
    'AT Events': {
      icon: <ClickIcon />,
      component: <ATEvents />,
    },
    Experiments: {
      icon: <FlashIcon />,
      component: <ExperimentSwitcher />,
    },
    Routing: {
      icon: <GlobeIcon />,
      component: <RouteInfo />,
    },
    ...(currentUser && {
      'User Info': {
        icon: <IdentificationIcon />,
        component: <UserInfo user={currentUser} />,
      },
    }),
    ...(isProductPage && {
      Product: {
        icon: <ClothesIcon />,
        component: <ProductInfo />,
      },
    }),
    ...(isShopPage && {
      Shop: {
        icon: <ShopIcon />,
        component: <ShopInfo />,
      },
    }),
  };
  const activeTool = MORE_TOOLS[activeToolKey] || TOOLS[activeToolKey];
  const toolsList = activeListKey === 'more' ? MORE_TOOLS : TOOLS;

  function handleClick() {
    setCookie(FORCE_TRAFFIC_WEIGHT, '0', { path: '/' });
    window.location.reload();
  }

  function handleMoreToolsClick() {
    const newKey = activeListKey === 'more' ? 'default' : 'more';

    setActiveListKey(newKey);
  }

  useEffect(() => {
    if (!open) {
      closeActiveTool();
    }
  }, [open]);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (mounted && (hasDepopChromeExt || !isProdEnv())) {
    return (
      <>
        <button className={styles.toggleButton} onClick={toggleAdminToolOpen}>
          {open ? <CancelIcon size={24} /> : <DepopGroupIconIcon size={32} />}
        </button>
        {open && (
          <div className={styles.modal}>
            {activeTool ? (
              <div>
                <div className={styles.actionIconWrapper}>
                  <button
                    className={styles.backButton}
                    onClick={closeActiveTool}
                  >
                    <ArrowBackIcon size={20} />
                  </button>
                </div>
                {activeTool.component}
              </div>
            ) : (
              <div className={styles.grid}>
                {Object.keys(toolsList).map((label) => (
                  <ToolButton
                    key={label}
                    label={label}
                    icon={toolsList[label].icon}
                    onClick={() => openTool(label)}
                  />
                ))}
              </div>
            )}
            {!activeTool && (
              <>
                <Button
                  block
                  className={styles.moreToolsButton}
                  onClick={handleMoreToolsClick}
                >
                  {activeListKey === 'default' ? 'Next' : 'Back'}
                </Button>
                <Button onClick={handleClick} block color="yellow">
                  Revert to old site
                </Button>
              </>
            )}
          </div>
        )}
      </>
    );
  }

  return null;
}
