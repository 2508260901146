import { useQuery } from '@tanstack/react-query';
import { getCookie } from 'cookies-next';
import { useParams } from 'next/navigation';

import { PRODUCT_EXTENDED_BY_SLUG } from '@/modules/ReactQuery/cacheKeys.ts';
import { getProductBySlugExtended } from '@/modules/product/api.ts';
import { LANGUAGE_KEY } from '@/constants/cookies.ts';
import { useExperiments } from '@/modules/experiments/useExperiments';
import { VARIANT_IDENTIFIER } from '@/modules/experiments/config';

export function useProductBySlugExtended() {
  const { slug } = useParams<{ slug: string }>();
  const { web_upfront_fees } = useExperiments(['web_upfront_fees']);

  return useQuery({
    queryKey: [PRODUCT_EXTENDED_BY_SLUG, slug],
    queryFn: async () => {
      const res = await getProductBySlugExtended({
        slug,
        lang: getCookie(LANGUAGE_KEY) || 'en',
        forceFeeCalculation: web_upfront_fees === VARIANT_IDENTIFIER,
      });
      return res.data;
    },
  });
}
