import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DeviceState {
  userAgent?: string;
}

const initialState: DeviceState = {
  userAgent: '',
};

export const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    setUserAgent: (state, action: PayloadAction<string>) => {
      state.userAgent = action.payload;
    },
  },
});

export const { setUserAgent } = deviceSlice.actions;

export default deviceSlice.reducer;
