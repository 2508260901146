import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Input } from '@depop/web-ui-kit/Input';

import { ExperimentSwitcherRow } from './ExperimentSwitcherRow';
import styles from './styles.module.css';

import { CachedExperiments } from '@/modules/experiments/types';
import { RQ_PREVIEW_EXPS_CACHE } from '@/modules/ReactQuery/cacheKeys';
import {
  OPTIMIZELY_EXPERIMENTS,
  OpzlyExperimentName,
} from '@/modules/experiments/config';
import { useExperiments } from '@/modules/experiments/useExperiments';

export function ExperimentSwitcher() {
  const [filterTerm, setFilterTerm] = useState('');
  const client = useQueryClient();
  const rawCachedExps: CachedExperiments | undefined =
    client.getQueryData<CachedExperiments>([RQ_PREVIEW_EXPS_CACHE]);

  const allOpzlyExperimentKeys = Object.keys(
    OPTIMIZELY_EXPERIMENTS
  ) as unknown as OpzlyExperimentName[];

  const [cachedExps] = useExperiments(allOpzlyExperimentKeys, {
    deferred: true,
  });

  if (!cachedExps || !Object.keys(cachedExps).length) {
    return null;
  }

  const expsToRender = filterTerm
    ? Object.entries(cachedExps)
        .filter(([k]) => rawCachedExps?.[k])
        .filter(([k]) => k.includes(filterTerm))
    : Object.entries(cachedExps).filter(([k]) => rawCachedExps?.[k]);

  return (
    <div>
      <Input
        labelText="Filter experiments"
        id="adminTool__experimentSwitcher"
        onChange={(e) => setFilterTerm(e.target.value)}
        placeholder="e.g. web_1234_exp_name"
      />
      <table className={styles.table}>
        <tbody>
          {expsToRender.map(([key, variant]) => (
            <ExperimentSwitcherRow
              key={key}
              name={key}
              selectedVariant={variant as string}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
