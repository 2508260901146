import React, { useState } from 'react';
import { Input } from '@depop/web-ui-kit/Input';
import { getCookie, setCookie } from 'cookies-next';

import { PREVIEW_ENV_KEY } from '@/constants/cookies';

export function PreviewEnv() {
  const [value, setValue] = useState(getCookie(PREVIEW_ENV_KEY));

  function updateValue(value: string) {
    setValue(value);
    setCookie(PREVIEW_ENV_KEY, value);
  }

  return (
    <Input
      labelText="Preview environment"
      id="adminTool__previewEnv"
      value={value}
      onChange={(e) => updateValue(e.target.value)}
      onClear={() => updateValue('')}
    />
  );
}
