import React from 'react';
import { getCookie } from 'cookies-next';

import { Table } from '@/components/AdminToolbox/components/Table';
import { ACCESS_TOKEN_KEY } from '@/constants/cookies.ts';
import { User } from '@/modules/user/types.ts';

type Props = {
  user: User;
};

export function UserInfo({ user }: Props) {
  return (
    <Table
      rows={{
        'Access Token': getCookie(ACCESS_TOKEN_KEY),
        Id: user.id,
        Username: user.username,
        Email: user.email,
        Verified: user.verified ? 'true' : 'false',
        Country: user.country,
        'Has Boosted Listings Access': user.hasBoostedListingsAccess
          ? 'true'
          : 'false',
        'Has Bulk Listing': user.hasBulkListingAccess ? 'true' : 'false',
      }}
    />
  );
}
