import React, { useContext } from 'react';
import { useParams, usePathname } from 'next/navigation';

import styles from './styles.module.css';

import { Table } from '@/components/AdminToolbox/components/Table';
import { RefererContext } from '@/modules/activityTracker/RefererProvider.tsx';
import { getReferer } from '@/modules/activityTracker/referers.ts';
import { SelectLocation } from '@/components/SelectLocation';

export function RouteInfo() {
  const refererUrl = useContext(RefererContext);
  const pathname = usePathname();
  const params = useParams();

  return (
    <>
      <Table
        rows={{
          Pathname: pathname,
          Params: JSON.stringify(params),
          'Referer URL': refererUrl || 'NULL',
          'Referer Value': getReferer(refererUrl) || 'NULL',
        }}
      />
      <div className={styles.dropdowns}>
        <SelectLocation label="Location" />
      </div>
    </>
  );
}
