import http from '../http';
import { AxiosCompatibleResponse } from '../http/types';

import {
  AppleCredentials,
  LoginCredentials,
  LoginResponse,
  MFAChallengeResponse,
  MFADevice,
} from './types';

import {
  APPLE_CREDENTIALS_ENDPOINT,
  APPLE_LOGIN_ENDPOINT,
  LOGIN,
  MFA_CREATE_CHALLENGE_ENDPOINT,
  MFA_DEVICES_ENDPOINT,
} from '@/constants/endpoints';

export function login(
  credentials: LoginCredentials
): Promise<AxiosCompatibleResponse<LoginResponse>> {
  return http.post<LoginResponse>(LOGIN, credentials, { withDeviceId: true });
}

export function loginWithApple(
  appleCredentials: AppleCredentials
): Promise<AxiosCompatibleResponse<LoginResponse>> {
  return http.post(APPLE_LOGIN_ENDPOINT, appleCredentials, {
    withDeviceId: true,
  });
}

export function fetchAppleCredentials(appleCredentialRequest: {
  appleAuthCode: string;
  redirectURI: string;
}): Promise<AxiosCompatibleResponse<AppleCredentials>> {
  return http.post(APPLE_CREDENTIALS_ENDPOINT, appleCredentialRequest);
}

export function createMfaChallenge({
  preMfaToken,
  deviceId,
}: {
  preMfaToken?: string;
  deviceId: string;
}): Promise<AxiosCompatibleResponse<MFAChallengeResponse>> {
  return http.post(
    MFA_CREATE_CHALLENGE_ENDPOINT.replace(':deviceId', deviceId),
    null,
    {
      headers: {
        Authorization: `Bearer ${preMfaToken}`,
      },
    }
  );
}

export function fetchMfaDevices(
  preMfaToken: string
): Promise<AxiosCompatibleResponse<MFADevice[]>> {
  return http.get(MFA_DEVICES_ENDPOINT, {
    headers: {
      Authorization: `Bearer ${preMfaToken}`,
    },
  });
}
