'use client';

import { useEffect, useRef } from 'react';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { usePathname, useSearchParams } from 'next/navigation';

import styles from './styles.module.css';

export function ProgressBar() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const pathnameRef = useRef<string | null>(null);

  NProgress.configure({
    showSpinner: false,
    barSelector: '[role="progressbar"]',
    template: `<div class="${styles.progressBar}" role="progressbar"></div>`,
  });

  useEffect(() => {
    NProgress.done();
    return () => {
      /**
       * Ensures that the progress bar only displays for
       * subsequent client-side navs, and not on first load
       */
      if (pathnameRef.current) {
        NProgress.start();
      }
      pathnameRef.current = pathname;
    };
  }, [pathname, searchParams]);

  return null;
}
