export enum Language {
  en = 'en',
}

export enum Locale {
  ENUS = 'en-US',
  ENGB = 'en-GB',
  ENAU = 'en-AU',
  ENIE = 'en-IE',
  ENCA = 'en-CA',
  ENNZ = 'en-NZ',
  FR = 'fr-FR',
  DE = 'de-DE',
  IT = 'it-IT',
}

export type SupportedLocales = keyof LocalesEnabled;

export type LocalesEnabled = {
  [Locale.ENAU]?: boolean;
  [Locale.DE]?: boolean;
  [Locale.FR]?: boolean;
  [Locale.ENGB]?: boolean;
  [Locale.IT]?: boolean;
  [Locale.ENUS]?: boolean;
  [Locale.ENIE]?: boolean;
  [Locale.ENNZ]?: boolean;
  [Locale.ENCA]?: boolean;
};

export const languages = [
  {
    value: Language.en,
    locale: Locale.ENUS,
    name: 'English',
    display: true,
  },
  {
    value: Language.en,
    locale: Locale.ENGB,
    name: 'UK English',
    display: false,
  },
  {
    value: Language.en,
    locale: Locale.ENAU,
    name: 'AU English',
    display: false,
  },
  {
    value: Language.en,
    locale: Locale.ENCA,
    name: 'English',
    display: false,
  },
  {
    value: Language.en,
    locale: Locale.ENIE,
    name: 'IE English',
    display: false,
  },
  {
    value: Language.en,
    locale: Locale.ENNZ,
    name: 'NZ English',
    display: false,
  },
];
