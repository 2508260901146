'use client';

import React from 'react';
import { LoadingBall } from '@depop/web-ui-kit/LoadingBall';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import { Table } from '@/components/AdminToolbox/components/Table';
import { useProductBySlugBasic } from '@/modules/product/hooks/useProductBySlugBasic';
import { useProductBySlugExtended } from '@/modules/product/hooks/useProductBySlugExtended';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { getCurrencySymbolFromCurrencyCode } from '@/modules/currencies/helpers';
import { getProductPriceFromPricing } from '@/modules/price/helpers/getProductPriceFromPricing';

export function ProductInfo() {
  const { data: productBasic, isPending: productBasicPending } =
    useProductBySlugBasic();
  const { data: productExtended, isPending: productExtendedPending } =
    useProductBySlugExtended();
  const [, currentUser] = useCurrentUser();
  const userCountry = currentUser?.country;

  if (productBasicPending || productExtendedPending) {
    return <LoadingBall />;
  }

  if (!productBasic || !productExtended) {
    return <Text>Failed to fetch product data</Text>;
  }
  const { national_shipping_cost, international_shipping_cost, currency_name } =
    productExtended.pricing;
  const price = getProductPriceFromPricing(productExtended.pricing);
  const currencySymbol = getCurrencySymbolFromCurrencyCode(currency_name);
  const nationalShippingCost = national_shipping_cost?.total_price
    ? `${currencySymbol}${national_shipping_cost.total_price}`
    : 'N/A';
  const internationalShippingCost = international_shipping_cost?.total_price
    ? `${currencySymbol}${international_shipping_cost.total_price}`
    : 'N/A';

  return (
    <div>
      <Table
        rows={{
          'Product Id': productBasic.id,
          'Product Country': productBasic.country,
          'Product Slug': productBasic.slug,
          'Product Category Id': productBasic.category,
          'Product Brand Id': productBasic?.brand_id || 'N/A',
          'National Shipping Cost': nationalShippingCost,
          'International Shipping Cost': internationalShippingCost,
          'Eligible For Marketplace Fees': Boolean(price.currentPriceFees),
          'Eligible For Sales Tax': Boolean(price.currentSalesTax),
          'Seller Id': productBasic.seller.id,
          'Seller Username': productBasic.seller.username,
          'Seller Country': productBasic.seller.country,
          'Buyer Country': userCountry || '- Login for result -',
        }}
      />
    </div>
  );
}
