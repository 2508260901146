import { useEffect, useState } from 'react';

export function useDepopChromeExt() {
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    const el = document.querySelector('.depop-web-tool-enabled');

    if (el) {
      setIsEnabled(true);
    }
  }, []);

  return isEnabled;
}
