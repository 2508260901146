/* eslint-disable no-restricted-imports */
import { useRouter } from 'next/navigation';
import { useContext } from 'react';

import { isRouteMigrated } from './getRoutesManifest';
import { ROUTE_CHANGE_START_EVENT } from './constants';

import { isServerCheck } from '@/modules/env/isServerCheck';
import { LocationContext } from '@/modules/location/LocationContext';
import {
  replaceUsernameInPath,
  isProtectedRoute,
} from '@/modules/routing/helpers';
import { useCurrentUser } from '@/modules/user/useCurrentUser';

export function useExtendedRouter() {
  const [isAuthenticated] = useCurrentUser();
  const router = useRouter();
  const { userLocation, localisedRoutes, defaultLocation } =
    useContext(LocationContext);

  function extendedRouterPush(...args: Parameters<typeof router.push>) {
    document.dispatchEvent(new CustomEvent(ROUTE_CHANGE_START_EVENT));
    const [href, options] = args;
    const formattedHref = replaceUsernameInPath(href);

    if (!isRouteMigrated(formattedHref) && !isServerCheck()) {
      const isProtected = isProtectedRoute(href);

      if (!isAuthenticated && isProtected) {
        window.location.href = `/login?redirect=${encodeURIComponent(
          formattedHref
        )}`;
        return;
      }
      window.location.href = formattedHref;
      return;
    }

    const isLocalisedRoute = localisedRoutes.some((route) => {
      const strippedHref = formattedHref
        .split('/')
        .filter((item) => item.length)
        .shift();
      return strippedHref === route;
    });

    let locale = isLocalisedRoute ? userLocation : defaultLocation;
    if (!formattedHref.startsWith('/')) {
      locale += '/';
    }

    const localisedHref =
      locale === defaultLocation ? formattedHref : `/${locale}${formattedHref}`;
    return router.push(localisedHref, options);
  }

  return { ...router, push: extendedRouterPush };
}
