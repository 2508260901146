import { useParams } from 'next/navigation';
import { useQuery } from '@tanstack/react-query';

import { UrlParams } from '../types';

import { RQ_SHOP_USERNAME_KEY } from '@/modules/ReactQuery/cacheKeys';
import { fetchShopByUsername } from '@/modules/shop/api';

export function useShopByUsername() {
  const { username } = useParams<UrlParams>();

  return useQuery({
    queryKey: [RQ_SHOP_USERNAME_KEY, username],
    queryFn: () => fetchShopByUsername(username),
  });
}
