'use client';

import React, { useEffect, useState } from 'react';
import Script from 'next/script';

import { useTrackingProviders } from '@/modules/trackingProviders/useTrackingProviders';
import { CustomEvents } from '@/constants/customEvents';

type Props = {
  nonce: string;
};

const BING_ID = '247004282';

export function InitBing({ nonce }: Props) {
  const { getCurrentConsentChoices } = useTrackingProviders();
  const { analytics } = getCurrentConsentChoices();
  const [shouldInit, setShouldInit] = useState(false);

  function handleCookieAcceptance() {
    const { analytics } = getCurrentConsentChoices();
    if (analytics) {
      setShouldInit(true);
    }
  }

  useEffect(() => {
    setShouldInit(analytics);
  }, [analytics]);

  useEffect(() => {
    window.addEventListener(
      CustomEvents.CookieAcceptance,
      handleCookieAcceptance
    );

    return () => {
      window.removeEventListener(
        CustomEvents.SessionId,
        handleCookieAcceptance
      );
    };
  }, []);

  if (!shouldInit) {
    return null;
  }
  return (
    <Script
      id="bing"
      nonce={nonce}
      strategy="lazyOnload"
    >{`(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"${BING_ID}",enableAutoSpaTracking:true};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`}</Script>
  );
}
