import React from 'react';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import styles from './styles.module.css';

type Props = {
  rows: Record<string, React.ReactNode>;
};

export function Table({ rows }: Props) {
  function formatValue(value: React.ReactNode) {
    if (typeof value === 'string' || typeof value === 'number') {
      return value;
    }
    return JSON.stringify(value);
  }

  return (
    <table className={styles.table}>
      <tbody>
        {Object.entries(rows).map(([key, value]) => (
          <tr key={key}>
            <td>
              <Text>{`${key}:`}</Text>
            </td>
            <td>
              <code className={styles.rowValue}>{formatValue(value)}</code>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
