import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RearrangeChangeSet } from '@/modules/shop/types';
import { ListProduct } from '@/modules/product/types';

export interface ShopState {
  isRearrangeModeActive: boolean;
  rearrangedProductsChangeSet: RearrangeChangeSet[];
  initialRearrangeableProductList: ListProduct[];
  rearrangeableProductList: ListProduct[];
}

const initialState: ShopState = {
  isRearrangeModeActive: false,
  rearrangedProductsChangeSet: [],
  initialRearrangeableProductList: [],
  rearrangeableProductList: [],
};

export const shopSlice = createSlice({
  name: 'Shop',
  initialState,
  reducers: {
    setIsRearrangeModeActive: (
      state,
      action: PayloadAction<{
        isActive: boolean;
        isCancel?: boolean;
      }>
    ) => {
      state.isRearrangeModeActive = action.payload.isActive;
      state.rearrangedProductsChangeSet = [];

      if (action.payload.isCancel) {
        state.rearrangeableProductList = state.initialRearrangeableProductList;
      }
    },
    addRearrangedProductChangeSet: (
      state,
      action: PayloadAction<RearrangeChangeSet>
    ) => {
      state.rearrangedProductsChangeSet = [
        ...state.rearrangedProductsChangeSet,
      ].concat(action.payload);
    },
    setRearrangeableProductList: (
      state,
      action: PayloadAction<{
        productList: ListProduct[];
        isInitial?: boolean;
      }>
    ) => {
      if (action.payload.isInitial) {
        state.initialRearrangeableProductList = action.payload.productList;
      }
      state.rearrangeableProductList = action.payload.productList;
    },
  },
});

export const {
  setIsRearrangeModeActive,
  addRearrangedProductChangeSet,
  setRearrangeableProductList,
} = shopSlice.actions;

export default shopSlice.reducer;
