import React, { useEffect, useState } from 'react';
import { H3 } from '@depop/web-ui-kit/Typography/H3';
import { SecondaryTabs } from '@depop/web-ui-kit/SecondaryTabs';

import { ATEventRow } from './ATEventRow';
import styles from './styles.module.css';

import { EventTypes } from '@/modules/activityTracker/constants';
import { Spinner } from '@/components/Spinner';
import { Table } from '@/components/AdminToolbox/components/Table';
import { safeJsonParse } from '@/modules/storage/helpers';

type PolledEvent = {
  type: EventTypes;
  data: {
    schemaVersion: string;
    baseEvent: {
      eventType: EventTypes;
      transitionFrom: string;
    };
    [key: string]: unknown;
  };
};

const tabs = ['Pretty', 'Raw'];

export function ATEvents() {
  const [polledEvents, setPolledEvents] = useState<PolledEvent[]>([]);
  const [activeEvent, setActiveEvent] = useState<null | PolledEvent>(null);
  const [activeTab, setActiveTab] = useState(tabs[0]);

  function addPolledEvent(reqBody: unknown) {
    if (!reqBody) {
      return;
    }

    const body = safeJsonParse(reqBody.toString(), {});

    setPolledEvents((prev) => [
      ...prev,
      {
        type: body.baseEvent?.eventType,
        data: { ...body },
      },
    ]);
  }

  function onRowClick(data: PolledEvent) {
    setActiveEvent(data);
  }

  function onTabChange(e: React.ChangeEvent<HTMLInputElement>) {
    setActiveTab(e.target.value);
  }

  useEffect(() => {
    const origFetch = window.fetch;

    window.fetch = (...args) => {
      if (args[0].toString().includes('/at/event')) {
        addPolledEvent(args[1]?.body);
      }
      return origFetch(...args);
    };
  }, []);

  const {
    data: { schemaVersion, baseEvent, transitionFrom, ...fields },
  } = activeEvent || ({ data: {} } as PolledEvent);

  return (
    <div>
      <H3 className={styles.listenerText}>
        <Spinner className={styles.spinner} size="12px" />
        Polling events
      </H3>
      <div className={styles.grid}>
        <div>
          {polledEvents.map((event, i) => (
            <ATEventRow
              label={`${i + 1}. ${event.type}`}
              key={`${event.type}-${i}`}
              bold={activeEvent === event}
              onClick={() => onRowClick(event)}
            />
          ))}
        </div>
        {activeEvent && (
          <div className={styles.eventInfo}>
            <SecondaryTabs
              name="adminTool__experimentSwitcherTabs"
              tabs={tabs.map((tab) => ({ id: tab, label: tab, value: tab }))}
              activeValue={activeTab}
              onChange={onTabChange}
            />
            <div className={styles.eventInfoContent}>
              {activeTab === 'Pretty' ? (
                <Table
                  rows={{
                    ...fields,
                    transitionFrom: baseEvent.transitionFrom,
                  }}
                />
              ) : (
                <pre className={styles.eventInfoRaw}>
                  {JSON.stringify(activeEvent.data, null, 2)}
                </pre>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
