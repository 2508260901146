import React from 'react';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import styles from './styles.module.css';

type Props = {
  label: string;
  bold?: boolean;
  onClick: () => void;
};

export function ATEventRow({ label, bold, onClick }: Props) {
  return (
    <div className={styles.eventRow}>
      <Text
        className={styles.eventRowButton}
        bold={bold}
        as="button"
        onClick={onClick}
      >
        {label}
      </Text>
    </div>
  );
}
