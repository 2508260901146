'use client';

import { useEffect } from 'react';

import { observeFontsLoaded } from './helpers';

export function ObserveFonts() {
  useEffect(() => {
    observeFontsLoaded();
  }, []);

  return null;
}
