import React from 'react';
import { RadioButtons, RadioLayout } from '@depop/web-ui-kit/RadioButtons';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { useQueryClient } from '@tanstack/react-query';

import {
  OPTIMIZELY_EXPERIMENTS,
  OpzlyExperimentName,
} from '@/modules/experiments/config';
import {
  getForcedVariantExperiments,
  setForcedVariantExperiments,
} from '@/modules/experiments/helpers';
import { RQ_PREVIEW_EXPS_CACHE } from '@/modules/ReactQuery/cacheKeys';
import { CachedExperiments } from '@/modules/experiments/types';

export function ExperimentSwitcherRow({
  name,
  selectedVariant,
}: {
  name: string;
  selectedVariant: string;
}) {
  const client = useQueryClient();
  const [newValue, setNewValue] = React.useState('');

  const configuredVariants = Object.entries(
    OPTIMIZELY_EXPERIMENTS[name as OpzlyExperimentName]
  )
    .filter(([label]) => label !== 'forceVariant')
    .map(([label, value]) => ({
      id: `radio-id-${name}-${label}`,
      labelText: label,
      value: String(value),
    }));

  async function onChangeVariant(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;

    let forcedExperiments: string | undefined =
      await getForcedVariantExperiments();

    // Ensures we don't end up with duplicate entries
    if (forcedExperiments) {
      forcedExperiments = forcedExperiments
        .split(',')
        .map((exp) => exp.split(':'))
        .filter(([key]) => key !== name)
        .map(([key, value]) => `${key}:${value}`)
        .join(',');
    }

    const experimentsToOverride = [
      ...(forcedExperiments ? forcedExperiments?.split(',') : []),
      `${name}:${value}`,
    ];

    // Set cookie so the changes persist on reload
    setForcedVariantExperiments(experimentsToOverride);

    // Update the cache with the overridden variant so we get an instant change
    client.setQueryData(
      [RQ_PREVIEW_EXPS_CACHE],
      (cached: CachedExperiments | undefined) => {
        const updateableCopy = {
          ...cached,
        };

        for (const exp of experimentsToOverride) {
          const [expName, decision] = exp.split(':');

          if (updateableCopy[expName]) {
            updateableCopy[expName].decision = decision;
          }
        }

        return updateableCopy;
      }
    );

    setNewValue(value);
  }

  return (
    <tr>
      <td>
        <Text type="caption1">{name}</Text>
        <RadioButtons
          activeValue={newValue || selectedVariant}
          layout={RadioLayout.inline}
          name={`exp-switcher-radios-${name}`}
          onChange={onChangeVariant}
          options={configuredVariants}
        />
      </td>
    </tr>
  );
}
