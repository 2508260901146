'use client';

import React, { useRef } from 'react';
import { Provider } from 'react-redux';
import { ConfigureStoreOptions } from '@reduxjs/toolkit';

import { getClientStore, RootState } from './stores/storeClient';

type Props = {
  children: React.ReactNode;
  preloadedState?: Partial<RootState>;
  clientStoreConfig?: Partial<ConfigureStoreOptions>;
};

function ReduxProvider({ children, preloadedState, clientStoreConfig }: Props) {
  /*
  DS 2025-03-24 see https://etsy.enterprise.slack.com/archives/C08K5FLESGK
  Storing the state in a ref fixes an issue that next appear to have introduced which
  causes the layout file to re-render which is resetting the redux state and causing
  the cookie banner to disappear before people can set their preferences.
  Given it's happened here, even if next fix, it could happen again, so we might as
  well handle like this.
  */
  const storeRef = useRef<ReturnType<typeof getClientStore>>(null);
  if (!storeRef.current) {
    storeRef.current = getClientStore(preloadedState, clientStoreConfig);
  }
  return <Provider store={storeRef.current}>{children}</Provider>;
}

export { ReduxProvider };
