'use client';

import React from 'react';
import { LoadingBall } from '@depop/web-ui-kit/LoadingBall';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import { Table } from '@/components/AdminToolbox/components/Table';
import { useShopByUsername } from '@/modules/shop/hooks/useShopByUsername';

export function ShopInfo() {
  const { data, isPending: shopPending } = useShopByUsername();
  const shop = data?.data;

  if (shopPending) {
    return <LoadingBall />;
  }

  if (!shop) {
    return <Text>Failed to fetch shop data</Text>;
  }

  return (
    <div>
      <Table
        rows={{
          'Shop Id': shop.id,
          'Shop Username': shop.username,
          Verified: shop.verified,
        }}
      />
    </div>
  );
}
